const books = [
  {
    id: 1,
    title: "Spiral",
    image:
      "https://res.cloudinary.com/ditqnzlil/image/upload/v1698169815/Spiral_Top_x6ngqv.jpg",
  },
  {
    id: 2,
    title: "2 In 1",
    image:
      "https://res.cloudinary.com/ditqnzlil/image/upload/v1698166628/Spiral_stack_top_1_dtllra.jpg",
  },
  {
    id: 3,
    title: "Regular Notebook",
    image:
      "https://res.cloudinary.com/ditqnzlil/image/upload/v1698164518/Notebook_Stacked_mlkzr9.jpg",
  },
];

export default books;
